<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Mis pagos</h1>
    </div>

    <div class="table-container">
      <div
          class="
          table-header
          d-flex
          flex-column flex-md-row
          justify-content-end
          align-items-center
        "
      >
        <div>
          <!-- Filtros -->
        </div>

        <section class="d-flex align-items-center">
          <RefreshButton text="Actualizar" @click="loadData"/>

          <SearchBar
              placeholder="Buscar por ID de pago o número de factura"
              @search="onSearch"
          />
          <b-button
              :to="{ name: 'upload-voucher-empty' }"
              variant="none"
              class="button button-primary ml-2"
              v-if="user && user.type === 2"
          >Subir comprobante
          </b-button>
        </section>
      </div>
      <div class="mb-0">
        <table class="custom-table">
          <thead>
          <tr>
            <th>ID</th>
            <th>Fecha de registro</th>
            <th>Descripción de pago</th>
            <th>Monto de pago</th>
            <th>Número de comprobante</th>
            <th>Fecha de comprobante</th>
            <th>Estado</th>
            <th class="pr-md-2">Opciones</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="o in list" :key="o.id">
            <td class="cell-center">{{ o.code }}</td>
            <td class="cell-center" v-localDate="o.registerDate"></td>
            <td>{{ truncateText(o.description, 70) }}</td>
            <td class="cell-center" v-decimal:pen="o.amount"></td>
            <td class="cell-center">{{ o.voucherCode }}</td>
            <td
                class="cell-center"
                v-localDate="o.voucher ? o.voucher.issueDate : null"
            ></td>
            <td class="cell-center">
                <span :class="`badge ${getStatus(o.status).color}`">{{
                    getStatus(o.status).label
                  }}</span>
            </td>

            <td class="cell-center pr-md-2 position-relative">
              <Dropdown>
                <template slot="dropdown-content">
                  <DropdownItem
                      @click="download(o.voucher.asset)"
                      text="Descargar comprobante"
                      v-if="o.voucher"
                  />
                  <DropdownItem
                      text="Subir comprobante"
                      :to="{ name: 'upload-voucher', params: { code: o.code } }"
                      v-if="o.status === PaymentStatus.PENDING.key && !o.disabled"
                  />
                  <DropdownItem
                      text="Ver motivo de rechazo"
                      @click="seeRejectReason(o.rejectReason)"
                      v-if="o.status === PaymentStatus.REJECTED.key"
                  />
                  <DropdownItem
                      text="Subir nota de crédito"
                      :to="{ name: 'upload-credit-note', params: { code: o.code } }"
                      v-if="o.status === PaymentStatus.REJECTED.key && !o.disabled"
                  />
                </template>
              </Dropdown>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
            :total="total"
            :page="page"
            :page-size="pageSize"
            :pages="pages"
            :changePage="changePage"
            :changePageSize="changePageSize"
        />
      </div>
    </div>

    <RejectReasonModal :show="showRejectReasonModal"
                       @hide="showRejectReasonModal = false"
                       :reject-reason="selectedRejectReason"/>
  </div>
</template>

<script>
import {Constants, Util} from "@/core/utils";
import {PaymentService} from "@/core/services";
import {PaginationMixin} from "@/core/mixins";


import SearchBar from "@/core/components/table/SearchBar";
import {Dropdown, DropdownItem, RefreshButton, RejectReasonModal} from "@/core/components";
import {mapGetters} from "vuex";

export default {
  components: {
    SearchBar,
    RefreshButton,
    Dropdown,
    DropdownItem,
    RejectReasonModal
  },
  name: "PaymentsPage",
  mixins: [PaginationMixin],
  data() {
    return {
      tooltipType: null,
      list: [],
      listOriginal: [],
      PaymentStatus: Constants.PaymentStatus,
      showRejectReasonModal: false,
      selectedRejectReason: null
    };
  },
  props: {
    title: String,
  },
  computed: {
    ...mapGetters(
        {
          user: 'auth/getUser',
        },
    ),
  },
  methods: {
    getStatus(key) {
      return Util.searchValue(key, Constants.PaymentStatus);
    },
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);

        const params = {
          page: this.page - 1,
          size: this.pageSize
        };
        const data = {search: this.search};
        const response = await PaymentService.getPayments(data, params);
        this.list = response.payload.content;
        this.listOriginal = this.list;
        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (error) {
        console.error(error);
        // TODO Handle error
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    download(asset) {
      if (asset)
        Util.downloadFile(asset.url, asset.name);
    },
    seeRejectReason(rejectReason) {
      this.selectedRejectReason = rejectReason;
      this.showRejectReasonModal = true
    },
    truncateText(text, length) {
      if (text.length > length) {
        return text.substring(0, length) + '...';
      }
      return text;
    },
  },
  async mounted() {
    await this.loadData(this.page, this.pageSize);
  },
};
</script>

<style lang="stylus" scoped></style>
