<template>
  <b-card tag="article" no-body style="max-width: 640px" class="custom-card supplier-card">
    <b-card-header
        header-tag="section"
        class="custom-card-header supplier-card-header d-flex align-items-center"
    >
      <h3>Datos del proveedor</h3>
    </b-card-header>
    <b-card-body class="custom-card-body supplier-card-body">
      <div class="alert-container">
        <InfoBadge text="Para continuar con el uso de la plataforma se requiere lo siguientes datos" :icon="require('@/core/assets/images/components/icon-alert-yellow.svg')" class="warning" />
      </div>
      <form class="custom-form supplier-form" @submit.prevent="save">
        <div class="form-group">
          <label for="mobile">Celular *</label>
          <input
              id="mobile"
              type="text"
              maxlength="9"
              v-model.trim="form.mobile"
              autocomplete="off"
              placeholder="Ingresar celular"
              class="custom-form-control"
              :class="{
              'custom-form-control-error': $v.form.mobile.$error}"
          />
          <form-error
              message="Celular es requerido"
              v-if="$v.form.mobile.$error && !$v.form.mobile.required"
          ></form-error>
          <form-error message="Ingresa un celular válido de 9 dígitos"
                      v-if="$v.form.mobile.$error && !$v.form.mobile.mobile"></form-error>
        </div>

        <div class="form-group">
          <label for="email">Correo electrónico *</label>
          <input
              id="email"
              type="text"
              v-model.trim="form.email"
              autocomplete="off"
              placeholder="Ingresar correo electrónico"
              class="custom-form-control"
              :class="{'custom-form-control-error':$v.form.email.$error}"/>
          <form-error message="Correo electrónico es requerido"
                      v-if="$v.form.email.$error && !$v.form.email.required"></form-error>
          <form-error message="Ingresa un correo electrónico válido"
                      v-if="$v.form.email.$error && !$v.form.email.email"></form-error>

          <div class="form-group-buttons text-right">
            <button @click="cancel" class="button button-cancel" type="button">
              Cancelar
            </button>
            <button class="button button-primary" type="submit">Guardar</button>
          </div>
        </div>
      </form>
    </b-card-body>
  </b-card>
</template>

<script>
import {email, required} from "vuelidate/lib/validators";
import {default as InfoBadge} from "@/core/components/Alert";
import {CustomValidators, Alert} from '@/core/utils';
import FormError from "@/core/components/FormError.vue";
import {UserService} from "@/core/services";
import {mapGetters} from "vuex";

export default {
  name: "SupplierForm",
  components: {
    InfoBadge,
    FormError
  },
  data() {
    return {
      form: {
        mobile: null,
        email: null,
      },
    };
  },
  validations: {
    form: {
      mobile: {required, mobile: CustomValidators.mobile,},
      email: {required, email},
    },
  },
  computed: {
    ...mapGetters(
        {
          user: 'auth/getUser',
        },
    )
  },
  methods: {
    async save() {
      this.$v.$touch();
      if (this.$v.$error) return;
      const data = {...this.form};
      try {
        const resp = await UserService.updatePusherDetails(data);
        await this.$store.commit('auth/user', resp.payload);
        await this.$router.push({name: 'profile'});
      } catch (e) {
        Alert.error(e.errors.message);
        console.error(e)
      }
    },
    cancel() {
      this.$store.dispatch('auth/logout').then(() => this.$router.push({name: 'login'}))
    }
  },
  created(){
    this.form.email = this.user.email;
    this.form.mobile = this.user.mobile;
  }
};
</script>

<style lang="stylus">
@import '../Styles/supplier-form';
</style>
