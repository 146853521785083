var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"voucher page-container"},[_vm._m(0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-8"},[_c('b-card',{staticClass:"custom-card",attrs:{"tag":"article","no-body":""}},[_c('b-card-header',{staticClass:"custom-card-header d-flex align-items-center",attrs:{"header-tag":"section"}},[_c('h3',[_vm._v("Carga de archivos")])]),_c('b-card-body',{staticClass:"custom-card-body"},[_c('Alert',{attrs:{"text":("Tipos de archivos soportados: " + (_vm.acceptedFiles.join(', ')))}}),_c('ul',{staticClass:"list list-unstyled"},[_c('li',[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"identifierType"}},[_vm._v("Tipo de comprobante * ")]),_c('FormSelect',{attrs:{"id":"identifierType","items":_vm.voucherTypes,"defaultOption":"Elegir tipo de comprobante","showError":_vm.$v.voucherType.$error &&
                    !_vm.$v.voucherType.required},model:{value:(_vm.voucherType),callback:function ($$v) {_vm.voucherType=$$v},expression:"voucherType"}}),(
                    _vm.$v.voucherType.$error &&
                    !_vm.$v.voucherType.required
                  )?_c('FormError',{attrs:{"message":"Tipo de comprobante es requerido"}}):_vm._e()],1),(!_vm.code && (_vm.voucherType === 'manualInvoice' || _vm.voucherType === 'manualReceipt'))?_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"identifierType"}},[_vm._v("Empresa * ")]),_c('FormSelect',{attrs:{"id":"identifierType","items":_vm.businesses,"defaultOption":"Elegir empresa","showError":_vm.$v.businessId.$error &&
                    !_vm.$v.businessId.required},model:{value:(_vm.businessId),callback:function ($$v) {_vm.businessId=$$v},expression:"businessId"}}),(
                    _vm.$v.businessId.$error &&
                    !_vm.$v.businessId.required
                  )?_c('FormError',{attrs:{"message":"Empresa es requerida"}}):_vm._e()],1):_vm._e(),(_vm.voucherType === 'manualInvoice' || _vm.voucherType === 'manualReceipt')?_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"paymentDate"}},[_vm._v("Fecha de comprobante *")]),_c('FormDatepicker',{staticClass:"custom-form-control",staticStyle:{"border":"0"},attrs:{"label-id":"paymentDate","show-calendar":_vm.showIssueDate,"max":new Date()},on:{"show":function($event){_vm.showIssueDate = true},"hidden":function($event){_vm.showIssueDate = false}},model:{value:(_vm.issueDate),callback:function ($$v) {_vm.issueDate=$$v},expression:"issueDate"}}),(
                    _vm.$v.issueDate.$error &&
                    !_vm.$v.issueDate.required
                  )?_c('FormError',{attrs:{"message":"Fecha de comprobante es requerido"}}):_vm._e()],1):_vm._e(),(_vm.voucherType === 'manualInvoice' || _vm.voucherType === 'manualReceipt')?_c('div',{staticClass:"form-group custom-form"},[_c('label',{attrs:{"for":"voucherCode"}},[_vm._v("Número de comprobante *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.voucherCode),expression:"voucherCode",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                    'custom-form-control-error': _vm.$v.voucherCode.$error,
                  },attrs:{"id":"voucherCode","type":"text","maxlength":"11","autocomplete":"off","placeholder":"Ingresar número de comprobante"},domProps:{"value":(_vm.voucherCode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.voucherCode=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.$v.voucherCode.$error && !_vm.$v.voucherCode.required)?_c('FormError',{attrs:{"message":"Número de comprobante es requerido"}}):_vm._e()],1):_vm._e(),(_vm.voucherType === 'manualInvoice' || _vm.voucherType === 'manualReceipt')?_c('div',{staticClass:"form-group custom-form"},[_c('label',{attrs:{"for":"voucherCode"}},[_vm._v("Monto total *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.total),expression:"total",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                    'custom-form-control-error': _vm.$v.total.$error,
                  },attrs:{"id":"voucherCode","type":"text","maxlength":"11","autocomplete":"off","placeholder":"Ingresar total"},domProps:{"value":(_vm.total)},on:{"input":function($event){if($event.target.composing){ return; }_vm.total=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.$v.total.$error && !_vm.$v.total.required)?_c('FormError',{attrs:{"message":"Total es requerido"}}):_vm._e(),(_vm.$v.total.$error && !_vm.$v.total.decimal)?_c('FormError',{attrs:{"message":"Ingrese un monto válido"}}):_vm._e()],1):_vm._e()]),(_vm.code)?_c('li',[_vm._v(" ID de pago: "),_c('span',{staticClass:"text-secondary"},[_vm._v(_vm._s(_vm.payment.code))])]):_vm._e(),(_vm.code)?_c('li',[_vm._v(" Monto de pago: "),_c('span',{directives:[{name:"decimal",rawName:"v-decimal:pen",value:(_vm.payment.amount),expression:"payment.amount",arg:"pen"}],staticClass:"text-secondary"})]):_vm._e()]),_c('section',{staticClass:"form-content"},[_c('div',{staticClass:"col-12 custom-form custom-form--supplier",staticStyle:{"padding":"0"}},[(!_vm.code)?_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"description"}},[_vm._v("Detalle de producto / servicio *")]),_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.description),expression:"description",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                    'custom-form-control-error': _vm.$v.description.$error,
                  },attrs:{"id":"description","type":"text","autocomplete":"off","maxlength":"200"},domProps:{"value":(_vm.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.description=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),(
                    _vm.$v.description.$error &&
                    !_vm.$v.description.required
                  )?_c('FormError',{attrs:{"message":"Detalle de producto / servicio es obligatorio"}}):_vm._e()],1):_vm._e()])]),_c('div',{staticClass:"custom-form"},[_c('FileInput',{attrs:{"acceptFile":_vm.acceptedFiles && _vm.acceptedFiles.length === 0 ? ['.none'] : _vm.acceptedFiles,"id-file":"input-files","multiple":true},on:{"onChange":_vm.onChange}}),(_vm.$v.files.$error)?_c('form-error',{attrs:{"message":"Debe seleccionar al menos un archivo, como máximo 2"}}):_vm._e(),_c('div',{staticClass:"form-group-buttons text-right"},[_c('router-link',{staticClass:"button button-cancel",attrs:{"to":{ name: 'payments' }}},[_vm._v(" Cancelar ")]),_c('button',{staticClass:"button button-primary",attrs:{"type":"submit"},on:{"click":_vm.submit}},[_vm._v(" Procesar ")])],1),(_vm.errors.length > 0)?_c('div',{staticClass:"form-errors mt-3"},[_c('Alert',{staticClass:"warning mb-2",attrs:{"text":"Por favor, solucione los siguientes problemas o caso contrario genera una nota de crédito para cargar una nueva factura.","icon":require('@/core/assets/images/components/icon-alert-yellow.svg')}}),_c('b',[_c('span',{staticClass:"error"},[_vm._v("Errores: "+_vm._s(_vm.errors.length))])]),_c('br'),_vm._l((_vm.errors),function(e,i){return _c('span',{key:(i + "-error"),staticClass:"error"},[_vm._v(_vm._s(e)),_c('br')])})],2):_vm._e()],1)],1)],1)],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-title-container"},[_c('h1',{staticClass:"page-title"},[_vm._v("Cargar comprobante")])])}]

export { render, staticRenderFns }